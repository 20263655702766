import React from 'react'
import { useAuth } from './useProvideAuth'
import { Button, Icon } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';

interface Props {

}

const YouAreNotLoggedIn: React.FC<Props> = (props: Props) => {
    const auth = useAuth();

    return (
        <div style={{ textAlign: "center", marginTop: "3rem" }}>
            <p><FormattedMessage id="youAreNotLoggedIn" /></p>
            <Button onClick={() => auth.login()} size="large" primary>
                <Icon name='sign in' /> <FormattedMessage id="login" />
            </Button>
        </div>
    )
}

export default YouAreNotLoggedIn

import React, { useEffect, useState } from 'react'
import { IntlShape } from 'react-intl';
import { Button, CheckboxProps, Form, Modal } from 'semantic-ui-react';
import { getErrorMessages, getErrorsData, removeMessageError, setPropertyValue } from 'utils/Utils';
import MessageError from 'components/MessageError';
import * as portalService from 'services/PortalService';
import { useToasts } from 'react-toast-notifications';
import produce from "immer";

interface IProps {
    hideModal: () => void;
    onSaveSuccess: () => void;
    intl: IntlShape;
}

const NotificationSettingsModal = (props: IProps) => {
    const { formatMessage, locale } = props.intl;
    const { addToast } = useToasts();
    const [loadingContent, setContentLoading] = useState(true);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(null);
    const [form, setForm] = useState({ notificationSettings: null });

    useEffect(() => {
        (async function () {
            try {
                const { data } = await portalService.getNotificationSettings(locale);
                setForm({ notificationSettings: data });
            } catch (error) {
                const errorMsg = getErrorMessages(error.response);
                addToast(errorMsg, { appearance: "error", autoDismiss: true });
                props.hideModal();
            } finally {
                setContentLoading(false);
            }
        })();
    }, [])

    const handlePropertyChange = (propName: string, propValue: any) => {
        const _form = produce(form, draft => {
            setPropertyValue(draft, propName, propValue);
        });
        setForm(_form);
        setErrors({ ...removeMessageError(propName, errors) });
    }

    const handleCheckBoxChange = async (event: React.FormEvent<HTMLInputElement>, data: CheckboxProps) => {
        const { name, checked } = data;
        handlePropertyChange(name, checked);
    }

    const updateNotificationSettings = async () => {
        try {
            setLoading(true);
            await portalService.updateNotificationSettings(form);
            props.onSaveSuccess();
            props.hideModal();
        } catch (error) {
            const data = getErrorsData(error.response);
            setErrors(data);
        } finally {
            setLoading(false);
        }
    }

    return (
        <Modal size="tiny" open={true}  >
            <Modal.Header>{formatMessage({ id: "notificationSettings" })}</Modal.Header>
            <Modal.Content>
                {loadingContent && <Form loading />}
                {!loadingContent &&
                    <Form loading={loading} error={errors !== null} size="small">
                        <MessageError errors={errors} />

                        {form?.notificationSettings?.map((x, idx) => (
                            <Form.Checkbox key={idx} toggle
                                label={x.description}
                                name={`notificationSettings[${idx}].activeFg`}
                                checked={x.activeFg ?? false}
                                onChange={handleCheckBoxChange}
                            />
                        ))}

                    </Form>
                }
            </Modal.Content>
            <Modal.Actions>
                <React.Fragment>
                    <Button disabled={loading || loadingContent} onClick={() => props.hideModal()}>
                        {props.intl.formatMessage({ id: "cancel" })}
                    </Button>
                    <Button primary disabled={loading || loadingContent} onClick={updateNotificationSettings} >
                        {props.intl.formatMessage({ id: "save" })}
                    </Button>
                </React.Fragment>
            </Modal.Actions>
        </Modal>
    )
}

export default NotificationSettingsModal;
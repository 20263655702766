import React, { Fragment, useEffect } from 'react'
import { useAuth } from './useProvideAuth'
import { Loader } from 'semantic-ui-react';
import YouAreNotLoggedIn from './YouAreNotLoggedIn';

interface Props {

}

const Login: React.FC<Props> = (props: Props) => {
    const auth = useAuth();

    useEffect(() => {
        auth.login()
    }, [])

    return (
        <Fragment>
            <Loader inline />
            {!auth.loginInProgress &&
                <YouAreNotLoggedIn />
            }
        </Fragment>
    )
}

export default Login

import React, { useState } from 'react'
import { IntlShape } from 'react-intl';
import { Button, Form, Modal, TextAreaProps } from 'semantic-ui-react';
import { getErrorsData, getUtcNoonDate, removeMessageError } from 'utils/Utils';
import MessageError from 'components/MessageError';
import * as portalService from 'services/PortalService';
import CustomDatePicker, { CustomDatePickerProps } from './CustomDatePicker';
import { registerLocale } from 'react-datepicker';
import fr from "date-fns/locale/fr";
import { v4 as uuidv4 } from "uuid";
import moment from 'moment';

interface IProps {
    hideModal: () => void;
    onSaveSuccess: (debtor: any) => void;
    intl: IntlShape;
    data: IPostponementPaymentRequestModalData;
}

export interface IPostponementPaymentRequestModalData {
    debtor: any;
}

const PostponementPaymentRequestModal = (props: IProps) => {
    registerLocale("fr", fr);
    const { data: { debtor } } = props;
    const minDate = new Date(debtor.case.postponementPaymentRequestMinDate.replace('Z', ''));
    const maxDate = new Date(debtor.case.postponementPaymentRequestMaxDate.replace('Z', ''));
    const { formatMessage, locale } = props.intl;
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(null);
    const [form, setForm] = useState({
        confirmationNumber: uuidv4(),
        requestedDate: null
    });

    const handleTextAreaChange = (e: React.FormEvent<HTMLTextAreaElement>, data: TextAreaProps) => {
        const { name, value } = data;
        setForm(prev => ({ ...prev, [name]: value }));
        setErrors({ ...removeMessageError(name, errors) });
    }

    const handleDateChange = (date: Date, _: React.SyntheticEvent<any>, data?: CustomDatePickerProps) => {
        const { name } = data;
        setForm(prev => ({ ...prev, [name]: date }));
        setErrors({ ...removeMessageError(name, errors) });
    }

    const submitPostponementPaymentRequest = async () => {
        try {
            setLoading(true);
            const _form = { ...form, requestedDate: getUtcNoonDate(form.requestedDate) };
            await portalService.submitPostponementPaymentRequest(_form);
            const { data } = await portalService.getGetDebtor(locale);
            props.onSaveSuccess(data);
            props.hideModal();
        } catch (error) {
            const data = getErrorsData(error.response);
            setErrors(data);
        } finally {
            setLoading(false);
        }
    }

    return (
        <Modal size="small" open={true}  >
            <Modal.Header>{formatMessage({ id: "postponementPaymentRequest" })}</Modal.Header>
            <Modal.Content>
                <Form loading={loading} error={errors !== null} size="small">
                    <MessageError errors={errors} />
                    <Form.Group widths="equal">
                        <Form.Field>
                            <label>{formatMessage({ id: "postponementPaymentConfirmationNumber" })}</label>
                            <input value={form.confirmationNumber} disabled />
                        </Form.Field>
                        <Form.Field>
                            <label>{formatMessage({ id: "nextPaymentDate" })}</label>
                            <input value={moment(debtor.case.nextPaymentDate).format("YYYY-MM-DD")} disabled />
                        </Form.Field>
                    </Form.Group>

                    <Form.Field required>
                        <label>{formatMessage({ id: "requestedPostponeDate" })}</label>
                        <CustomDatePicker isClearable fluid autoComplete="off"
                            selected={form.requestedDate}
                            name="requestedDate"
                            onChange={handleDateChange}
                            placeholderText={formatMessage({ id: "requestedPostponeDate" })}
                            locale={locale}
                            minDate={minDate}
                            maxDate={maxDate}
                        />
                    </Form.Field>
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <React.Fragment>
                    <Button disabled={loading} onClick={() => props.hideModal()}>
                        {props.intl.formatMessage({ id: "cancel" })}
                    </Button>
                    <Button primary disabled={loading} onClick={submitPostponementPaymentRequest} >
                        {props.intl.formatMessage({ id: "submit" })}
                    </Button>
                </React.Fragment>
            </Modal.Actions>
        </Modal>
    )
}

export default PostponementPaymentRequestModal;
import React, { useState, useEffect, createContext, useContext } from "react";
import * as authService from "../services/AuthService";
import ReactGA from "react-ga4";
import produce from "immer";
import Median from "median-js-bridge";
interface AuthProviderState {
    error: any;
    isAuthenticated: boolean;
    loggedIn: boolean;
    user: any;
}

function useProvideAuth(loggedIn: boolean) {
    const [authState, setAuthState] = useState<AuthProviderState>({
        error: null,
        isAuthenticated: false,
        loggedIn,
        user: {}
    });
    const [loginInProgress, setLoginInProgress] = useState(true);

    useEffect(() => {
        if (!window.location.pathname.includes("/mobileLoginRedirect")) {
            loadUser();
        }
    }, [])

    const loadUser = async () => {
        try {
            const response = await authService.getUserInfos();
            const isAuthenticated = response.data.isAuthenticated
            setAuthState(({
                isAuthenticated,
                loggedIn: loggedIn || isAuthenticated,
                user: response.data.user,
                error: null
            }) as any);

            if (response.data.isAuthenticated) {
                //Set Authentification in GA
                ReactGA.set({ userId: response.data.user.debtorId });

                //OneSignal user login
                Median.onReady(async () => {
                    const result = await Median.onesignal.login(response.data.user.debtorId);
                    if (result?.success) {
                        Median.onesignal.tags.setTags({
                            tags: {
                                caseId: response.data.user.caseId.toString()
                            }
                        });
                    }
                })
            }
        } catch (error) {
        } finally {
            setLoginInProgress(false);
        }
    }

    const login = async () => {
        window.location.href = '/AzureADB2C/Account/SignIn';
    }

    const logout = () => {
        window.location.href = '/AzureADB2C/Account/SignOut';
    }

    const setAlertCount = (count: number) => {
        if (!authState.user) return;
        const _authState = produce(authState, draft => {
            draft.user.messageCount = count;
        });
        setAuthState(_authState);
    }

    return {
        error: authState.error,
        isAuthenticated: authState.isAuthenticated,
        loggedIn: authState.loggedIn,
        user: authState.user,
        login: () => login(),
        logout: () => logout(),
        loginInProgress: loginInProgress,
        setAlertCount: (count: number) => setAlertCount(count)
    }
}

interface IAuthContextInterface extends AuthProviderState {
    login: () => void;
    logout: () => void;
    loginInProgress: boolean;
    setAlertCount: (count: number) => void
}

const authContext = createContext<IAuthContextInterface>({
    isAuthenticated: false,
    loggedIn: false,
    error: null,
    user: {},
    login: () => { },
    logout: () => { },
    loginInProgress: false,
    setAlertCount: () => { }
});

export const useAuth = () => {
    return useContext(authContext);
};

export const AuthProvider: React.FC<{ children: React.ReactNode, loggedIn: boolean }> = ({ children, loggedIn }) => {
    const auth = useProvideAuth(loggedIn);
    return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};
import React, { FunctionComponent, useEffect } from 'react';
import {  Container } from 'semantic-ui-react';
import { TopMenu } from './TopMenu';
import { useAuth } from './useProvideAuth';
import { useIntl } from 'react-intl';
import ReactGA from "react-ga4";
import { Footer } from './Footer';
import { useMediaQuery } from 'react-responsive';
import { addBodyClass, removeBodyClass } from '../utils/Utils';
import { DeviceContext, DeviceType } from '../utils/Contexts';

interface Props {

}

export const Layout: FunctionComponent<Props> = (props) => {
    const auth = useAuth();
    const intl = useIntl();
    const { locale } = useIntl();
    const isTablet = useMediaQuery({ maxWidth: 1000 });
    const isMobile = useMediaQuery({ maxWidth: 768 });
    const bodyDeviceClass = isMobile ? ["mobile"] : (isTablet ? ["mobile", "tablet"] : ["computer"]);

    useEffect(() => {
        document.title = intl.formatMessage({ id: "document.title" });
    }, [locale])

    useEffect(() => {
        addBodyClass([...bodyDeviceClass]);
        return () => removeBodyClass([...bodyDeviceClass]);
    }, [bodyDeviceClass])

    useAnalytics('/');

    return (<>
        <DeviceContext.Provider value={isMobile ? DeviceType.Mobile : (isTablet ? DeviceType.Tablet : DeviceType.Computer)}>
            <TopMenu isAuthenticated={auth.isAuthenticated} user={auth.user} logOut={auth.logout} login={auth.login} loggedin={auth.loggedIn} />
            <Container className="Site-content Site-content--full">
                {props.children}
            </Container>
            <Footer />
        </DeviceContext.Provider>
    </>
    );
};

export const makePageView = (pageName: string) => ReactGA.send({ hitType: "pageview", page: pageName });

export const useAnalytics = (pageName: string) => useEffect(() => { makePageView(pageName); }, [pageName]);